* {
  box-sizing: border-box;
}

html {
  background-color: black;
}

@font-face {
  font-family: 'DIN';
  src: local('DIN'), url(./fonts/din-alternate-bold.otf) format('opentype');
}

:root {
  --toastify-color-progress-light: linear-gradient(to right, rgb(74, 6, 10), rgb(231, 37, 31));
  --toastify-color-progress-dark: linear-gradient(to right, rgb(74, 6, 10), rgb(231, 37, 31));
  --toastify-font-family: 'Roboto', sans-serif;
}
